import React, { useContext, useState } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { getContactPageUrl, getHomePageUrl } from '~/utils/routing';
import Link from 'next/link';
import SearchGoogleInput from '~/components/searchGoogleInput';
import { Trans } from '@lingui/react';
import CurrencySwitcher from '~/components/currencySwitcher';
import LangSwitcher from '~/components/langSwitcher';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const TopBar = ({ isHomepage }) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  return (
    <MobileMenu>
      {expandSearchBar ? (
        <>
          <SearchGoogleInput expandSearchBar={expandSearchBar} />
          <IconClose
            onClick={() => {
              setExpandSearchBar(false);
            }}
            className="Icon Icon-close Icon-lg"
          ></IconClose>
        </>
      ) : (
        <>
          <Link href={`${getHomePageUrl(appCtx)}`} passHref={true}>
            <a>
              <Logo
                alt="logo"
                height="32"
                width="32"
                src={`${publicRuntimeConfig.ASSETS_PREFIX}/images/bs-logo-white-small.svg`}
              ></Logo>
            </a>
          </Link>

          {!isHomepage && !expandSearchBar && (
            <IconOpen
              onClick={() => {
                setExpandSearchBar(true);
              }}
              className="Icon Icon-search Icon-lg"
            ></IconOpen>
          )}

          <MenuToggle>
            <HiddenCheckbox type="checkbox" />

            <HamburgerPart></HamburgerPart>
            <HamburgerPart></HamburgerPart>
            <HamburgerPart></HamburgerPart>

            <MenuUL>
              <Link href={`${getHomePageUrl(appCtx)}`} passHref={true}>
                <a>
                  <MenuLi>
                    <Trans id="navigation.home" />
                  </MenuLi>
                </a>
              </Link>

              <Link href={`${getContactPageUrl(appCtx)}`} passHref={true}>
                <a>
                  <MenuLi>
                    <Trans id="navigation.contact" />
                  </MenuLi>
                </a>
              </Link>

              {!(appCtx.state.lang === 'en' && appCtx.state.culture === 'us') && (
                <>
                  <HR />
                  <LangSwitcherWrapper>
                    {/* <LangSwitcher /> */}
                    <CurrencySwitcherWrapper>
                      <CurrencySwitcher />
                    </CurrencySwitcherWrapper>
                  </LangSwitcherWrapper>
                </>
              )}
            </MenuUL>
          </MenuToggle>
        </>
      )}
    </MobileMenu>
  );
};

const MenuToggle = styled.div`
  display: block;
  position: relative;
  display: block;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
`;

const HiddenCheckbox = styled.input`
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;

  &:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: white;
  }

  &:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  &:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  &:checked ~ ul {
    transform: none;
  }
`;
const HamburgerPart = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  margin-bottom: 3px;
  position: relative;
  background: white;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;

  &:first-child {
    transform-origin: 0% 0%;
  }

  &:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
`;
const MenuUL = styled.ul`
  position: absolute;
  width: 200px;
  margin: 16px 0 0 -150px;
  padding: 10px;
  background-color: #102a5e;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const MenuLi = styled.li`
  padding: 10px 0;
  font-size: 0.875em;
  line-height: 1.714285;
  margin-bottom: 0;
  color: White;
  cursor: pointer;
`;

const MobileMenu = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  margin-right: 32px;
  vertical-align: middle;
`;

const IconOpen = styled.a`
  color: white;
  cursor: pointer;
  margin-left: auto;
  margin-right: 16px;
`;

const IconClose = styled.a`
  color: white;
  cursor: pointer;
  margin-left: 20px;
`;

const HR = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.4);
  margin-top: 6px;
  width: 102%;
  margin-left: -9px;
`;

const LangSwitcherWrapper = styled.li`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  padding-top: 6px;

  &* > {
    outline: none !important;
  }

  div[class*='container'] {
    width: 50px;
    margin-right: 46px;
    margin-left: -2px;
  }
  &:focus,
  div[class*='container']:focus,
  div[class*='ValueContainer']:focus {
    /* outline: none !important; */
    /* border: none !important; */
  }

  div[class*='control'] {
    background: transparent;
    border: none !important;
    box-shadow: none !important;
    width: auto !important;
    margin-left: -10px !important;
  }
  div[class*='singleValue'] {
    color: white;
    font-size: 0.875em;
    line-height: 1.714285;
    cursor: pointer;
  }

  div[class*='option'] {
    font-size: 0.875em;
    line-height: 1.714285;
    cursor: pointer;
  }

  span[class*='indicatorSeparator'] {
    display: none;
  }

  div[class*='indicatorContainer'] {
    padding: 0px;
    color: white !important;
    cursor: pointer;
  }

  div[class*='ValueContainer'] {
    padding-right: 0px;
    text-align: left;
    /* justify-content: flex-end; */
    /* justify-content: flex-start; */
    padding: 0px !important;
    justify-content: flex-start;

    input {
      color: transparent !important;
    }
  }
`;

const CurrencySwitcherWrapper = styled.div`
  div[class*='container'] {
    margin-right: 5px;
    width: 70px;
    margin-left: 0px;
  }
`;
export default TopBar;
